<template>
    <GuestLayout>
        <div class="d-flex">
            <div class="col-lg-6 d-none d-lg-block bg-login-image"></div>
            <div class="col-lg-6 col-md-12 bg-image min-height-full">
                <div class="px-5 center-form-y">
                    <div class="text-center py-3">
                        <img src="@/assets/img/logos/logo.png" class="loginLogo">
                        <p class="h3 mt-4 mb-3 font-weight-bold font-white">Admin Panel</p>
                    </div>

                    <div class="alert alert-danger" v-if="error">
                        {{ error }}
                    </div>

                    <form @submit.prevent="onSubmit">
                        <TextField
                            v-model="form.email"
                            type="email"
                            placeholder="Username or Email Address"
                            required
                            :errors="errors.email"
                        >
                        </TextField>

                        <TextField
                            v-model="form.password"
                            placeholder="Password"
                            type="password"
                            required
                            :errors="errors.password"
                        >
                        </TextField>

                        <Button 
                            color="warning"
                            block
                            type="submit" 
                            :loading="loading"
                        >
                            Log In
                        </Button>
                    </form>
                </div>
            </div>
        </div>
    </GuestLayout>
</template>

<script>
    export default {
        data: () => ({
            loading: false,
            error: null,
            errors: {},
            form: {
                email: null,
                password: null
            }
        }),

        methods: {
            async onSubmit() {
                this.loading = true
                this.error = null
                this.errors = {}

                try {
                    const { loggedIn } = await this.$store.dispatch('auth/login', this.form)
                    if (loggedIn) this.$router.push({ name: 'home' })
                } catch (e) {
                    if (e.response) {
                        const { data } = e.response
                        this.errors = data.errors || {}
                        this.error = !data.errors ? data.message : null
                        this.password = null
                    } else {
                        this.$toast.info("Please check your Internet connection", "We're Very Sorry", { position: 'topRight' })
                    }
                }

                this.loading = false
            }
        }
    }
</script>

<style lang="css">
    .min-height-full {
        min-height: 100vh;
    }

    .center-forgot-password-form-y {
        margin-top: 20vh;
    }

    .center-form-y {
        margin-top: 20vh;
    }

    .font-white {
        color: white !important;
    }

    .font-dark-grey {
        color: #717171 !important;
    }

    .font-green {
        color: #00b661 !important;
    }

    .rounded-10 {
        border-radius: 10px !important;
    }

    form.user {
        width: 100% !important;
    }

    form.user > .custom-checkbox.small {
        label {
          line-height: 1.5rem;
        }
    }

    form.user > .form-control-user {
        font-size: 0.8rem;
        border-radius: 10px !important;
        padding: 1.5rem 1rem;
    }

    form.user > .btn-user {
        font-size: 0.8rem;
        border-radius: 10px !important;
        padding: 0.75rem 1rem;
    }

    .loginLogo {
        width: 25%;
    }
</style>
